.fun{
    width: 100%;
    padding: 50px 0 80px 0;
    box-sizing: border-box;
    background-image: url('../../assets/function_bg.png');
    background-size: 100% 100%;
}
.fun-top{
    text-align: center;
    color: #fff;
    font-size: 30px;
}
.line{
    height: 2px;
    width: 40px;
    margin: auto;
    background-color: #3D5AD7;
    margin-top: 20px;
}
.fun-box{
    width: 750px;
    height: 400px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
}
.fun-img{
    width: 100px;
    height: 100px;
    margin: 20px 136px;
}
.fun-img img{
    width: 100%;
    height: 100%;
}
.fun-item{
    width: calc(50% - 3px);
    height: 50%;
    color: #fff;
    border-top: 1px solid #585a61;
    border-left: 1px solid #585a61;
}
.fun-item:nth-child(2){
    border-right: 1px solid #585a61;
}
.fun-item:nth-child(3){
    border-bottom: 1px solid #585a61;
}
.fun-item:nth-child(4){
    border-right: 1px solid #585a61;
    border-bottom: 1px solid #585a61;
}
.fun-item-name{
    font-size: 20px;
    text-align: center;
}