.home-footer{
    width: 100%;
    height: 300px;
    background-color: #090b1a;
    padding: 50px 0;
    box-sizing: border-box;
}
.wid{
    width: 1180px;
    margin: 0 auto;
    position: relative;
}
.foolist1:nth-child(3){
    width: 380px;
}
.foolist1{
    display: inline-block;
    color: #7b7d8a;
    width: 300px;
}
.foolist1 p:first-child{
    color: #fff;
    font-size: 18px;
}
.foolist1 p{
    cursor: pointer;
    font-size: 14px;
    margin-bottom: 20px;
}
.foolist1 p:hover{
    color: #fff;
}
.wx{
    width: 100px;
    height: 100px;
    position: absolute;
    top: 50px;
    right: 20px;
}
.wx img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
}