.mid{
    width: 1180px;
    height: 150px;
    margin: 30px auto;
}
.mid-list{
    display: flex;
    width: 100%;
    height: 100%;
}
.mid-list img{
    width: 53px;
    height: 50px;
    vertical-align: bottom;
}
.mid-item{
    flex: 1;
    height: 100px;
    margin-top: 20px;
    border-left: 1px solid #e5e5e5;
    padding-left: 30px;
    padding-top: 10px;
    box-sizing: border-box;
}
.mid-item:nth-child(4){
    flex: 1;
    border-right: 1px solid #e5e5e5;
}
.item-top{
    display: inline-block;
    margin-left: 20px;
    font-size: 20px;
}
.item-top span:first-child{
    display: block;
    margin-bottom: 10px;
}