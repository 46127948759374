.nav{
    height: 60px;
    padding: 0 100px!important;
    box-sizing: border-box;
    opacity: 0.8;
    z-index: 9;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    font-size: 16px;
    min-width: 1200px;
}
.nav:hover{
    background-color: rgb(51, 51, 51);
    opacity: 0.8;
}
.navScr {
    background-color: rgb(51, 51, 51);
    opacity: 0.8;
}
.nav-left{
    display: inline-block;
}
.nav img{
    width: 32px;
    height: 32px;
    vertical-align: middle;
    display: inline-block;
    margin-top: -6px;
    margin-right: 20px;
}
.nav span{
    color: #fff;
    font-size: 22px;
    cursor: pointer;
    height: 60px;
    line-height: 60px;
    display: inline-block;
}
.nav-list{
    display: inline-block;
    margin-left: 100px;
}
.nav-list div{
    display: inline-block;
    color: #ffffffb3;
    margin-right: 30px;
    cursor: pointer;
    height: 58px;
    line-height: 60px;
}
.nav-list div:hover, .nav-right div:hover{
    color: #fff;
}
.nav-right div{
    height: 60px;
    line-height: 60px;
    color: #fff;
    cursor: pointer;
}
.big{
    font-size: 18px;
    border-bottom: 2px solid #fff;
    color: #fff!important;
    transition: all .5s;
}
