@CHARSET "UTF-8"; .main {
	display:flex;
  flex-wrap: wrap;
}
.content-mi {
  padding-top: 40px;
}
.header {
	line-height: 46px;
    font-size: 30px;
    color: #333;
    letter-spacing: 0;
  margin-top:50px;
    text-align: center;
}
.main-item {
  display: block;
    cursor: pointer;
    float: left;
    -webkit-transition: -webkit-box-shadow .3s linear;
    transition: -webkit-box-shadow .3s linear;
    -moz-transition: box-shadow .3s linear,-moz-box-shadow .3s linear;
    transition: box-shadow .3s linear;
    transition: box-shadow .3s linear,-webkit-box-shadow .3s linear,-moz-box-shadow .3s linear;
  position:realative;
	width:392px;
  height:402px
}
.main-item-content {
  position:realative;
	height:100%;
  padding: 10px;
}
.main-item-content-header {
  position:realative;
	height:220px
}
.main-item-content-body {
  position:realative;
	height:170px;
  border: 1px solid #eee;
    border-top: 0;
    padding: 20px 48px 22px;
    color: #333;
    letter-spacing: 0;
}
.header-text {
  position:realative;
	font-size: 22px;
    height: 38px;
    line-height: 38px;
}
.body-text {
	position:realative;
    line-height: 24px;
    margin-top: 10px;
    overflow: hidden;
    font-size: 14px;
    color: #999;
}
.footer {
	position:relative;
  line-height: 46px;
  font-size: 30px;
  color: #333;
  letter-spacing: 0;
  text-align: center;
  height:100px;
}
.footer-content {
  justify-content: center;
  position:relative;
  display:flex;
  height: 198px;
}
.footer-left {
	height:100%
}
.des {
	position:relative;
  width:1180px;
  display:flex;
  flex-wrap:wrap;
  border-bottom: 1px solid #eee;

}
.des-left {
  width:750px;
  height:100%;
  padding-left:10px
}
.footer-desc-left {
  position:relative;
	font-size: 22px;
  line-height: 38px;
  font-weight: 700;
}
.footer-desc-left-header {
	font-size: 14px;
  line-height: 24px;
  margin-top: 10px;
}
.desc-right {
	flex:1;
  height:100%;
}
.advantage-title {
	position:relative;
  line-height: 46px;
  font-size: 30px;
  color: #333;
  letter-spacing: 0;
  margin-top:30px;
  text-align: center;
  height:87px;

}
.advatange-main {
	position:relative !important;
  height:130px;
  width:100%;
  display:flex;
  justify-content: center;
  margin-top:20px
}
.advantage-content {
	width:1300px;
  height:100%;
  display:flex;
  flex-wrap:wrap
}
.advantage-item {
	flex:1;
  height:100%;
  margin-left:50px;
  position:relative !important
}
.ad-item {
	position:relative !important;
  width:100%;
  height:100%;
}
.icon1 {
	position:absolute !important;
    left:10px;
    top:6px;
    width: 30px !important;
    height: 30px !important
}
.ad-right {
  height:100%;
  width:100%;
  padding-left:60px
}
.icon-right-content {
  position:relative !important;
  width:100%;
  font-size: 22px;
  height: 38px;
  line-height: 38px;
  font-weight: 700;
	}
.icon-right-content-bottom {
  position:relative !important;
  font-size: 14px;
  max-width:270px;
  line-height: 24px;
  margin-top: 10px;
}
