@CHARSET "UTF-8"; .main {
	padding-top: 50px;
  background-color: #fff;
  width: 100%;
  min-width: 1200px;
}
.compant-about {
	display: flex;
  justify-content:center;
}
.about-company-text {
	border-radius: 20px;
  padding: 40px 72px;
  color: #333;
  box-shadow: 0px 1px 20px 10px rgb(48 78 155 / 10%);
  background: #fff;
  min-width: 936px;
  max-width: 1150px;
  margin-bottom: 40px;
}
.about-company-bg {
	background:  no-repeat;
  background-position: center;
  margin-bottom: 35px;
  font-size: 34px;
  height: 118px;
  line-height: 118px;
  text-align: center !important;
  color: #000;
}
.about-company-note {
	font-size: 16px;
  line-height: 32px;
  margin-bottom: 32px;
}
.about-product {
	background: #fafbfc;
  padding: 50px 0 80px;
  display: flex;
  justify-content:center;
}
.about-product-main{
	min-width: 980px;
  max-width: 1194px;
}
.about-product-bg {
	font-size: 34px;
  height: 118px;
  line-height: 118px;
  text-align: center !important;
  margin-bottom: 54px;
  background:  no-repeat;
  background-position: center;
  color: #000;
}
.about-product-note {
	min-width: 866px;
  width: 1080px;
  max-width: 1080px;    
  overflow: hidden;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 1px 20px 10px rgb(48 78 155 / 10%);
  margin: 0 57px;
  /*height: 361px;*/
  display: flex;
  justify-content: space-between;
  align-items: cneter;
  margin-bottom: 70px;
}
.about-product-img-note {
  width: 100%
}
.about-product-img {
  width: 100%
}
.about-product-img-text {
	font-size: 16px;
  color: #333;
  width: 445px;
  padding-left: 45px;
}
.about-product-text {
	line-height: 40px;
  margin-top: 48px;
}
.about-develop {
	background: #fff;
  padding: 50px 0 80px;
  display: flex;
  justify-content: center;
}
.about-develop-main {
	min-width: 980px;
  max-width: 1194px;
}
.about-develop-bg {
	font-size: 34px;
  height: 118px;
  line-height: 118px;
  text-align: center !important;
  margin-bottom: 54px;
  background:  no-repeat;
  background-position: center;
  color: #000;
}