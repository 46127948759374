.brand{
    padding: 50px 0;
    width: 100%;
    box-sizing: border-box;
    background-image: url('../../assets/brand_bg.png');
    background-size: 100% 100%;
}
.brand-top{
    text-align: center;
    color: #333;
    font-size: 30px;
}
.line{
    height: 2px;
    width: 40px;
    margin: auto;
    background-color: #3D5AD7;
    margin-top: 20px;
}
.brand-item .img{
    width: 32px;
    height: 32px;
    vertical-align: top;
}
.img img{
    width: 100%;
    height: 100%;
}
.brand-box{
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    width: 1180px;
    height: 370px;
    margin: 50px auto;
}
.brand-item{
    border-top: 1px solid #e5e5e5;
    border-left: 1px solid #e5e5e5;
    width: calc(50% - 3px);
    height: 50%;
    padding: 50px;
    box-sizing: border-box;
}
.brand-item:nth-child(2){
    border-right: 1px solid #e5e5e5;
}
.brand-item:nth-child(3){
    border-bottom: 1px solid #e5e5e5;
}
.brand-item:nth-child(4){
    border-bottom: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;

}