.pro{
    width: 1180px;
    margin: 0 auto;
    padding: 50px 0;
    box-sizing: border-box;
}
.pro-top{
    text-align: center;
    color: #333;
    font-size: 30px;
}
.line{
    height: 2px;
    width: 40px;
    margin: auto;
    background-color: #3D5AD7;
    margin-top: 20px;
}
.pro-box{
    width: 100%;
    height: 400px;
    margin-top: 50px;
    display: flex;
    border: 1px solid #e5e5e5;
}
.pro-item{
    width: calc(50% - 2px);
    height: 100%;
    text-align: center;
}
.pro-item:first-child{
    border-right: 1px solid #e5e5e5;
}
.pro-item p:first-child{
    font-size: 35px;
    color: #4f515e;
    margin-bottom: 30px;
    margin-top: 30px;
}
.pro-item p{
    color: #7b7d8a;
    margin-bottom: 30px;
    font-size: 14px;
}